(function() {
	'use strict';

	var $ = avm.require('cash'),
		modals= document.querySelectorAll('.modal'),
		iframes = document.querySelectorAll('.modal iframe');

	for (var i = 0; i < modals.length; i++) {
		modals[i].addEventListener('hide.bs.modal', function() {
			var $modalContent = $(this).find('.modal-content'),
				$iframe = $modalContent.find('iframe'),
				$videoElement = $modalContent.find('video')[0];

			//	YouTube <iframe> video player
			if ($iframe.length !== 0) {
				// stop video playback before closing the Modal
				$iframe[0].contentWindow.postMessage('{ "event":"command", "func":"pauseVideo", "args":"" }', '*');
			} else if ($videoElement) {
				//	native HTML5 <video> player
				$videoElement.load();
			}
		});
	};

	for (var i = 0; i < iframes.length; i++) {
		var iframe = iframes[i];

		if (iframe.getAttribute('link') !== null && /youtube-nocookie/.test(iframe.getAttribute('link'))) {

			if (/MSIE|Trident\//.test(window.navigator.userAgent)) {
				// IE callback
				var parent = iframe.parentNode,
					link = iframe.getAttribute('link'),
					height = iframe.getAttribute('height'),
					width = iframe.getAttribute('width'),
					ifrm = document.createElement('iframe');

				parent.removeChild(iframe);
				ifrm.setAttribute('src', link);
				ifrm.setAttribute('width', width);
				ifrm.setAttribute('height', height);
				parent.appendChild(ifrm);
			} else {
				var YTVideoElements = document.querySelectorAll('[data-target*=' + iframe.closest('[data-cmp-name="cmp-modal"]').getAttribute('id') + ']')

				for (var i = 0; i < YTVideoElements.length; i++) {
					initLazyLoad(YTVideoElements[i]);
				};

				function initLazyLoad(element) {
					element.addEventListener('click', loadVideo, true);

					function loadVideo() {
						document.querySelector(element.getAttribute('data-target') + ' iframe').contentDocument.querySelector('a').click();
						element.removeEventListener('click', loadVideo, true);
					}
				}
			}
		}
	}

})();
